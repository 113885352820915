import React, { useMemo } from 'react';
import { Button, Checkbox, Dropdown } from 'antd';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { SearchOutlined } from '@ant-design/icons';
import {
  BoxInput,
  Container,
  DateInputBox,
  FilterOptions,
  SearchBar,
} from './styles';
import { getOrderPaymentStatus } from '../../../../utils/getOrderPaymentStatus';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';
import { useAuth } from '../../../../hooks/AuthContext';

const CheckboxGroup = Checkbox.Group;

const filterCheckboxOptions = [
  {
    label: 'Tem despachante',
    value: 'have_virtual_dispatcher',
  },
];

export function Filters({
  filterCorrectionStatus,
  setFilterCorrectionStatus,
  filterPaymentStatus,
  setFilterPaymentStatus,
  setSearchWord,
  search,
  clearSearch,
  searchWord,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  checkedFilters,
  setCheckedFilters,
}) {
  const paymentStatus = [
    {
      key: '1',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('')}
        >
          Todos
        </Button>
      ),
    },

    {
      key: '2',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('STARTED')}
        >
          Iniciada
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('STARTED_PIX')}
        >
          Pix gerado
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('AUTHORIZED')}
        >
          Pré-autorizada
        </Button>
      ),
    },
    {
      key: '5',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('PAID')}
        >
          Paga
        </Button>
      ),
    },
    {
      key: '6',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('IN_ANALYSIS')}
        >
          Analisando o risco
        </Button>
      ),
    },
    {
      key: '7',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('DECLINED')}
        >
          Negada
        </Button>
      ),
    },
    {
      key: '8',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterPaymentStatus('CANCELED')}
        >
          Cancelada
        </Button>
      ),
    },
  ];

  const correctionStatus = [
    {
      key: '1',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('')}
        >
          Todos
        </Button>
      ),
    },

    {
      key: '2',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('NOT_STARTED')}
        >
          Disponível
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('STARTED')}
        >
          Em produção
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('COORDINATOR_REVISION')}
        >
          Revisão Coordenador
        </Button>
      ),
    },
    {
      key: '8',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('PROOFREADER_CORRECTION')}
        >
          Revisão Especialista
        </Button>
      ),
    },
    {
      key: '5',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('COMPLETED')}
        >
          Concluída
        </Button>
      ),
    },
    {
      key: '6',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('UNAFFORDABLE')}
        >
          Recurso incabível
        </Button>
      ),
    },
    {
      key: '7',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('CANCELED')}
        >
          Cancelada
        </Button>
      ),
    },
  ];

  registerLocale('pt', pt);
  setDefaultLocale('pt-BR');

  const hangleCheckboxFilter = (list) => {
    setCheckedFilters(list);
  };

  const { user } = useAuth();

  const isUserAdminMaster = useMemo(() => {
    if (user && user.permission_level === 'master') {
      return true;
    }

    return false;
  }, [user]);

  return (
    <Container>
      <SearchBar>
        <BoxInput>
          <input
            id="search"
            type="text"
            name="search"
            placeholder="Buscar"
            onChange={(event) => setSearchWord(event.target.value)}
            onKeyDown={(event) => event.key === 'Enter' && search()}
            value={searchWord}
          />
        </BoxInput>

        <Button
          icon={<SearchOutlined />}
          style={{ marginLeft: 20 }}
          type="primary"
          onClick={() => {
            search();
          }}
        >
          Buscar
        </Button>

        <Button type="link" onClick={clearSearch}>
          Limpar Busca
        </Button>
      </SearchBar>

      <FilterOptions>
        <DateInputBox>
          <label>Data Inicial</label>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        <DateInputBox>
          <label>Data Final</label>
          <ReactDatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        {isUserAdminMaster && (
          <>
            <Dropdown menu={{ items: paymentStatus }} placement="bottomLeft">
              <Button
                style={{
                  minWidth: 260,
                  height: 38,
                  display: 'flex',
                  alignItems: 'center',
                }}
                icon={<MdKeyboardArrowDown size={20} />}
              >
                <strong style={{ marginRight: 5 }}>Status de pagamento:</strong>
                <p>
                  {filterPaymentStatus
                    ? `  ${getOrderPaymentStatus(filterPaymentStatus)}`
                    : '  Todos'}
                </p>
              </Button>
            </Dropdown>

            <Dropdown menu={{ items: correctionStatus }} placement="bottomLeft">
              <Button
                style={{
                  minWidth: 260,
                  height: 38,
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 15,
                }}
                icon={<MdKeyboardArrowDown size={20} />}
              >
                <strong style={{ marginRight: 5 }}>Status de correção: </strong>
                <p>
                  {filterCorrectionStatus
                    ? `  ${getOrderCorrectionStatus(filterCorrectionStatus)}`
                    : '  Todos'}
                </p>
              </Button>
            </Dropdown>

            <CheckboxGroup
              style={{ marginTop: 15, marginLeft: 15 }}
              options={filterCheckboxOptions}
              value={checkedFilters}
              onChange={hangleCheckboxFilter}
            />
          </>
        )}
      </FilterOptions>
    </Container>
  );
}
