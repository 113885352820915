/* eslint-disable react/jsx-curly-newline */
import { Button, Modal, message } from 'antd';
import React, { useState } from 'react';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { BoxInput } from '../../../styles';
import { api } from '../../../../../services/api';

export function AddProductSubQuestionModal({
  isModalAddQuestionsOpen,
  setIsModalAddQuestionsOpen,
  productQuestions,
  setProductQuestions,
  productQuestionOpen,
  productId,
  getProduct,
}) {
  const [addSubquestionLoading, setAddSubquestionLoading] = useState(false);
  const [questionName, setQuestionName] = useState('');
  const [questionObservation, setQuestionObservation] = useState('');
  const [questionLimiteCaracteries, setQuestionLimiteCaracteries] =
    useState('');
  const [questionScore, setQuestionScore] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  function clearNumber(priceInput) {
    return Number(String(priceInput).replace('.', ''));
  }

  function formatNumber(priceInput) {
    return (Number(clearNumber(priceInput)) / 100).toFixed(2);
  }

  async function productQuestionActions() {
    if (!questionScore || !questionName || !questionLimiteCaracteries) {
      messageApi.open({
        type: 'error',
        content: 'Todos os campos são obrigatórios',
      });

      return;
    }

    const subquestionScoreSum = productQuestionOpen.subquestions.reduce(
      (accumulator, currentObject) => accumulator + Number(currentObject.score),
      0,
    );

    const isSubquestionScoreOverQuestionScore =
      subquestionScoreSum + Number(questionScore) > productQuestionOpen.score;

    if (isSubquestionScoreOverQuestionScore) {
      messageApi.open({
        type: 'error',
        content: 'A nota máxima do subitem ultrapassa a nota da questão',
      });

      return;
    }

    const subquestionCharacterLimitSum =
      productQuestionOpen.subquestions.reduce(
        (accumulator, currentObject) =>
          accumulator + Number(currentObject.character_limit),
        0,
      );

    const isSubquestionCharacterLimitOverQuestionCharacterLimit =
      subquestionCharacterLimitSum + Number(questionLimiteCaracteries) >
      productQuestionOpen.character_limit;

    if (isSubquestionCharacterLimitOverQuestionCharacterLimit) {
      messageApi.open({
        type: 'error',
        content:
          'O limite de caractéries do subitem ultrapassa a o limite de caractéries da questão',
      });

      return;
    }

    try {
      setAddSubquestionLoading(true);

      await api.post('/products/subquestions', {
        title: questionName,
        character_limit: questionLimiteCaracteries,
        score: questionScore,
        product_question_id: productQuestionOpen.id,
        product_id: productId,
      });

      const findProductQuestionIndex = productQuestions.findIndex(
        (productQuestionItem) =>
          productQuestionItem.id === productQuestionOpen.id,
      );

      const newProductQuestions = [...productQuestions];

      newProductQuestions[findProductQuestionIndex].subquestions.push({
        question_id: productQuestionOpen.id,
        id: uuid(),
        title: questionName,
        character_limit: questionLimiteCaracteries,
        score: questionScore,
        observation: questionObservation,
      });

      setProductQuestions([...newProductQuestions]);
      setQuestionName('');
      setQuestionLimiteCaracteries('');
      setQuestionScore('');
      setQuestionObservation('');
      toast.success('Questão adicionada com sucesso');
      getProduct();
      setAddSubquestionLoading(false);
    } catch (error) {
      toast.error('Erro ao cadastrar sub questão');
      setAddSubquestionLoading(false);
    }
  }

  console.log('Logs', {
    productQuestionOpen,
  });

  return (
    <>
      <Modal
        title="Cadastrar sub questão"
        open={isModalAddQuestionsOpen}
        footer={null}
        onCancel={() => setIsModalAddQuestionsOpen(false)}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="questionName">Título</label>
          <input
            id="questionName"
            type="text"
            name="questionName"
            placeholder="Título da questão"
            value={questionName}
            onChange={(event) => setQuestionName(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionLimiteCaracteries">
            Limite de caracteries
          </label>
          <input
            id="questionLimiteCaracteries"
            type="number"
            name="questionLimiteCaracteries"
            placeholder="Limite de caracteries da questão"
            value={questionLimiteCaracteries}
            onChange={(event) =>
              setQuestionLimiteCaracteries(event.target.value)
            }
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionScore">Nota máxima</label>
          <input
            id="questionScore"
            type="text"
            name="questionScore"
            placeholder="Nota máxima da questão"
            value={questionScore}
            onChange={(event) =>
              setQuestionScore(formatNumber(event.target.value))
            }
          />
        </BoxInput>

        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="observation">Observação</label>
          <input
            id="observation"
            type="text"
            name="observation"
            placeholder="Observação da questão"
            value={questionObservation}
            onChange={(event) => setQuestionObservation(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            onClick={() => productQuestionActions()}
            loading={addSubquestionLoading}
          >
            Adicionar Subquestão
          </Button>
        </div>
      </Modal>
      {contextHolder}
    </>
  );
}
