/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
import {
  AppstoreOutlined,
  HomeOutlined,
  LoadingOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Divider,
  FloatButton,
  Image,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  message,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdUploadFile } from 'react-icons/md';
import ReactQuill from 'react-quill';
import ReactDatePicker from 'react-datepicker';
import {
  BoxForm,
  BoxInput,
  ButtonContainer,
  Container,
  DateInputBox,
  IconImage,
  LabelButton,
  ProductImages,
  ResourceList,
  TextEditorBox,
  UploadButton,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';
import { ProductQuestions } from './ProductQuestions';
import { ModalSendEmailToPresale } from './ModalSendEmailToPresale';
import { DispatcherVirtual } from './DispatcherVirtual';

const { Option } = Select;

export function ProductUpdate() {
  const [initialValues, setInitialValues] = useState({
    name: '',
    price: '',
    installments: '',
    product_overview: '',
    description: '',
    thumbnail_url: '',
    stamp_url: '',
    imagem_about_product_url: '',
    link_video: '',
    card_background: '',
    card_tag_bottom: '',
    card_tag_top: '',
    url: '',
    limit_time_to_buy_with_promotion: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const { product_id } = useParams();
  const [price, setPrice] = useState();
  const [priceDiscount, setPriceDiscount] = useState(0);

  const [percentDiscountCash, setPercentDiscountCash] = useState(0);
  const [percentDiscountPix, setPercentDiscountPix] = useState(0);

  const [availableForSale, setAvailableForSale] = useState(false);
  const [showInStore, setShowInStore] = useState(false);
  const [preSale, setPreSale] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [resourceModels, setResourceModels] = useState([]);
  const [hasProcurement, setHasProcurement] = useState(false);

  // Icones do produto
  const [isModalAddSummary, setIsModalAddSummary] = useState(false);
  const [summaryIcon, setSummaryIcon] = useState('');
  const [summaryTitle, setSummaryTitle] = useState('');
  const [summaryDescription, setSummaryDescription] = useState('');
  const [summaryIconPreview, setSummaryIconPreview] = useState('');
  const [productSummary, setProductSummary] = useState([]);

  // Questões
  const [isModalAddQuestionsOpen, setIsModalAddQuestionsOpen] = useState(false);
  const [isModalUpdateQuestionsOpen, setIsModalUpdateQuestionsOpen] =
    useState(false);
  const [questionId, setQuestionId] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [questionLimiteCaracteries, setQuestionLimiteCaracteries] =
    useState('');
  const [questionScore, setQuestionScore] = useState('');
  const [productQuestions, setProductQuestions] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [editorTextValue, setEditorTextValue] = useState('');

  const [productType, setproductType] = useState('DEFAULT');
  const [productCategorySelected, setProductCategorySelected] = useState('');
  const [productCategories, setProductCategories] = useState([]);

  const [useQuestionsToUpload, setUseQuestionsToUpload] = useState();

  const [
    dateLimiteToBuyProductDispatcher,
    setDateLimiteToBuyProductDispatcher,
  ] = useState(new Date());

  const [isModalSendEmailPreSale, setIsModalSendEmailPreSale] = useState(false);
  function clearPrice(priceInput) {
    return Number(String(priceInput).replace('.', ''));
  }

  function formatPrice(priceInput) {
    return (Number(clearPrice(priceInput)) / 100).toFixed(2);
  }

  async function updateProduct(values) {
    setButtonLoading(true);
    try {
      const {
        name,
        installments,
        product_overview,
        link_video,
        card_background,
        card_tag_bottom,
        card_tag_top,
        limit_time_to_buy_with_promotion,
      } = values;

      await api.put(`/products/${product_id}`, {
        name,
        price: clearPrice(price),
        installments,
        available_for_sale: availableForSale,
        show_in_store: showInStore,
        pre_sale: preSale,
        description: editorTextValue,
        category_id:
          productCategorySelected === 'null' ? null : productCategorySelected,
        product_overview,
        link_video,
        card_background,
        card_tag_bottom,
        card_tag_top,
        percent_discount_cash: percentDiscountCash,
        percent_discount_pix: percentDiscountPix,
        has_procurement: hasProcurement,
        price_discount: clearPrice(priceDiscount),
        type: productType,
        limit_date_to_buy: dateLimiteToBuyProductDispatcher,
        limit_time_to_buy_with_promotion,
        product_questions: productQuestions,
        use_questions_to_upload: useQuestionsToUpload,
      });
      toast.success('Producto atualizado com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao atualizar produto');
      setButtonLoading(false);
    }
  }

  async function getProduct() {
    try {
      const response = await api.get(`/products/show/${product_id}`);
      setInitialValues(response.data);
      setAvailableForSale(response.data.available_for_sale);
      setShowInStore(response.data.show_in_store);
      setPreSale(response.data.pre_sale);
      setProductQuestions(response.data.product_questions);
      setLoading(false);
      setPrice(formatPrice(response.data.price));
      setPriceDiscount(formatPrice(response.data.price_discount));
      setPercentDiscountCash(response.data.percent_discount_cash);
      setPercentDiscountPix(response.data.percent_discount_pix);
      setEditorTextValue(response.data.description);
      setProductCategorySelected(response.data.categoryId);
      setHasProcurement(response.data.has_procurement);
      setproductType(response.data.type);
      setDateLimiteToBuyProductDispatcher(response.data.limit_date_to_buy);
      setUseQuestionsToUpload(response.data.use_questions_to_upload);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao carregar produto');
    }
  }

  async function getProductResourceModels() {
    try {
      const response = await api.get(`/products/resource-model/${product_id}`);
      setResourceModels(response.data);
    } catch (error) {
      toast.error('Error ao carregar modelos de recursos');
    }
  }

  async function deleteProductResourceModels(product_resource_model_id) {
    try {
      await api.delete(`/products/resource-model/${product_resource_model_id}`);
      getProductResourceModels();
    } catch (error) {
      toast.error('Error ao carregar modelos de recursos');
    }
  }

  async function getProductSummary() {
    try {
      const response = await api.get(`/products/summary/${product_id}`);
      setProductSummary(response.data);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao carregar resumo do produto');
    }
  }

  async function deleteProductSummary(product_summary_id) {
    try {
      await api.delete(`/products/summary/${product_summary_id}`);

      getProductSummary();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao remover resumo do produto');
    }
  }

  async function getProductCategories() {
    try {
      const response = await api.get('/store/product-categories');
      setProductCategories(response.data);
    } catch (error) {
      toast.error('Erro ao obter categorias');
    }
  }

  const [isModalAddBannerOpen, setIsModalAddBannerOpen] = useState(false);

  async function removeProductImage(prop) {
    setButtonLoading(true);
    try {
      await api.post(`/products/images/${product_id}`, {
        product_property: prop,
      });
      toast.success('Imagem removida com sucesso');

      getProduct();

      setButtonLoading(false);
    } catch (err) {
      toast.error('Erro ao remover imagem do produto');
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    getProduct();
    getProductResourceModels();
    getProductSummary();
    getProductCategories();
  }, []);

  /**
   * Upload de modelos de recursos
   */
  const uploadResourceModelImage = useCallback(async (event) => {
    try {
      if (event.target.files) {
        setUploadLoading(true);

        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', file, file.name);

        await api.post(`/products/resource-model/${product_id}`, formData);

        getProductResourceModels();

        setUploadLoading(false);
      }

      toast.success('Imagem adicionada com sucesso');
    } catch (error) {
      setUploadLoading(false);
      toast.error('Error ao fazer upload de imagem');
    }
  }, []);

  /**
   * Upload imagens do produto
   */
  const uploadProductImages = useCallback(async (event, propName) => {
    try {
      if (event.target.files) {
        setUploadLoading(true);

        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('product_property', propName);

        const response = await api.put(
          `/products/images/${product_id}`,
          formData,
        );
        setInitialValues(response.data);

        setUploadLoading(false);
      }

      toast.success('Imagem adicionada com sucesso');
    } catch (error) {
      setUploadLoading(false);
      toast.error('Error ao fazer upload de imagem');
    }
  }, []);

  /**
   * Cria resumo do produto
   */
  async function createProductSummary() {
    try {
      const formData = new FormData();
      formData.append('file', summaryIcon, summaryIcon.name);
      formData.append('title', summaryTitle);
      formData.append('description', summaryDescription);

      await api.post(`/products/summary/${product_id}`, formData);
      getProductSummary();
      toast.success('Resumo do produto adicionado com sucesso');
      setIsModalAddSummary(false);
      setSummaryIcon('');
      setSummaryTitle('');
      setSummaryDescription('');
      setSummaryIconPreview('');
    } catch (error) {
      toast.error('Erro ao adicionar resumo do produto');
    }
  }

  function uploadProductSummary(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      setSummaryIconPreview(URL.createObjectURL(file));
      setSummaryIcon(file);
    }
  }

  function closeModalAddProductQuestion() {
    setIsModalAddQuestionsOpen(false);
    setQuestionName('');
    setQuestionLimiteCaracteries('');
    setQuestionScore('');
    setIsModalUpdateQuestionsOpen(false);
  }

  function openModalToUpdateProductQuestion(productQuestion) {
    const {
      question_name,
      question_limit_caracteries,
      question_score,
      question_id,
    } = productQuestion;
    setQuestionName(question_name);
    setQuestionLimiteCaracteries(question_limit_caracteries);
    setQuestionScore(question_score);
    setIsModalUpdateQuestionsOpen(true);
    setQuestionId(question_id);
  }

  function excluseProductQuestion(id) {
    const productQuestionsNew = productQuestions.filter(
      (question) => question.id !== id,
    );
    setProductQuestions(productQuestionsNew);
  }

  function productQuestionActions(props) {
    const { action } = props;

    if (!questionScore || !questionName || !questionLimiteCaracteries) {
      messageApi.open({
        type: 'error',
        content: 'Todos os campos são obrigatórios',
      });

      return;
    }

    if (action === 'add') {
      setProductQuestions([
        ...productQuestions,
        {
          id: productQuestions.length + 1,
          questionName,
          questionLimiteCaracteries,
          questionScore,
        },
      ]);
      setQuestionName('');
      setQuestionLimiteCaracteries('');
      setQuestionScore('');
      toast.success('Questão adicionada com sucesso');
    }

    if (action === 'update') {
      const questionIndex = productQuestions.findIndex(
        (question) => question.id === questionId,
      );

      const newProductQuestions = [...productQuestions];
      newProductQuestions[questionIndex].questionName = questionName;
      newProductQuestions[questionIndex].questionLimiteCaracteries =
        questionLimiteCaracteries;
      newProductQuestions[questionIndex].questionScore = questionScore;
      setProductQuestions(newProductQuestions);

      setQuestionName('');
      setQuestionLimiteCaracteries('');
      setQuestionScore('');
      toast.success('Questão atualizada com sucesso com sucesso');
      setIsModalUpdateQuestionsOpen(false);
    }
  }

  function fakeSave() {
    toast.success('Produto atualizado com sucesso!');
  }

  function handleProductCategory(type) {
    setProductCategorySelected(type);
  }

  const isProductTypeDefault = useMemo(
    () => productType === 'DEFAULT' || productType === null,
    [productType],
  );

  function getProductTypeName(productTypeProp) {
    switch (productTypeProp) {
      case 'VIRTUAL_DISPATCHER':
        return 'Despachante Virtual';
      default:
        return 'Padrão';
    }
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(productQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProductQuestions(items);
  }

  const updateProductComponent = (
    <BoxForm>
      <Formik initialValues={initialValues} onSubmit={updateProduct}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <BoxInput>
              <label htmlFor="category">Tipo de produto</label>

              <input
                value={getProductTypeName(productType)}
                id="name"
                type="text"
                name="product_type"
                placeholder="Tipo do produto"
                disabled
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="name">Nome</label>
              <Field id="name" type="text" name="name" placeholder="Nome" />
            </BoxInput>

            {isProductTypeDefault && (
              <BoxInput>
                <label htmlFor="url">URL do produto</label>
                <Field
                  id="url"
                  type="text"
                  name="url"
                  placeholder="Url do produto"
                  disabled
                />
                <Button type="link">
                  <a
                    target="_blank"
                    href={`https://www.recursooficial.com.br/produto/${initialValues.url}`}
                    rel="noreferrer"
                  >
                    Link
                  </a>
                </Button>
              </BoxInput>
            )}

            <BoxInput>
              <label htmlFor="price">Preço de</label>
              <input
                id="price"
                type="text"
                name="price"
                placeholder="Preço de"
                value={price}
                onChange={(event) => setPrice(formatPrice(event.target.value))}
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="price_discount">Preço por</label>
              <input
                id="price_discount"
                type="text"
                name="price_discount"
                placeholder="Preço por"
                value={priceDiscount}
                onChange={(event) =>
                  setPriceDiscount(formatPrice(event.target.value))
                }
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="discount_cash">Desconto a vista %</label>
              <input
                id="discount_cash"
                type="number"
                name="discount_cash"
                placeholder="%"
                value={percentDiscountCash}
                onChange={(event) =>
                  setPercentDiscountCash(formatPrice(event.target.value))
                }
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="discount_pix">Desconto no PIX %</label>
              <input
                id="discount_pix"
                type="number"
                name="discount_pix"
                placeholder="%"
                value={percentDiscountPix}
                onChange={(event) =>
                  setPercentDiscountPix(formatPrice(event.target.value))
                }
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="installments">Número de parcelas</label>
              <Field
                id="installments"
                type="number"
                name="installments"
                placeholder="Número de parcelas"
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="available_for_sale">
                Disponível para venda ?
              </label>
              <Switch
                defaultChecked
                checked={availableForSale}
                onChange={() => {
                  setAvailableForSale(!availableForSale);
                  setShowInStore(!availableForSale);
                }}
              />
            </BoxInput>

            {isProductTypeDefault && (
              <BoxInput>
                <label htmlFor="show_in_store">Mostrar na loja ?</label>
                <Switch
                  defaultChecked
                  checked={showInStore}
                  onChange={() => setShowInStore(!showInStore)}
                />
              </BoxInput>
            )}

            {isProductTypeDefault && (
              <BoxInput>
                <label htmlFor="setPreSale">É pré-venda ?</label>
                <Switch
                  defaultChecked
                  checked={preSale}
                  onChange={() => setPreSale(!preSale)}
                />
              </BoxInput>
            )}
            <BoxInput>
              <label htmlFor="category">Categoria</label>
              <Select
                defaultValue={
                  productCategorySelected || 'Selecione a categoria'
                }
                style={{ width: 300, height: 38 }}
                onChange={handleProductCategory}
              >
                <Option key="null">Sem categoria</Option>
                {productCategories.map((categoryItem) => (
                  <Option key={categoryItem.id}>{categoryItem.name}</Option>
                ))}
              </Select>
            </BoxInput>

            {!isProductTypeDefault && (
              <DateInputBox>
                <label>Data limite para compra</label>
                <ReactDatePicker
                  selected={new Date(dateLimiteToBuyProductDispatcher)}
                  onChange={(date) => setDateLimiteToBuyProductDispatcher(date)}
                  locale="pt"
                  dateFormat="dd/MM/yyyy - hh:mm"
                  showTimeSelect
                />
              </DateInputBox>
            )}

            {!isProductTypeDefault && (
              <BoxInput>
                <label>Tempo limite para compra com promoção (minutos)</label>
                <Field
                  id="limit_time_to_buy_with_promotion"
                  type="number"
                  name="limit_time_to_buy_with_promotion"
                  placeholder="minutos"
                />
              </BoxInput>
            )}

            {isProductTypeDefault && (
              <BoxInput>
                <label htmlFor="procurement">Tem procuração eletronica ?</label>
                <Switch
                  defaultChecked
                  checked={hasProcurement}
                  onChange={() => setHasProcurement(!hasProcurement)}
                />
              </BoxInput>
            )}

            {isProductTypeDefault && (
              <BoxInput>
                <label htmlFor="useQuestionsToUpload">
                  Associar documentos por questão ?
                </label>
                <Switch
                  defaultChecked
                  checked={useQuestionsToUpload}
                  onChange={() =>
                    setUseQuestionsToUpload(!useQuestionsToUpload)
                  }
                />
              </BoxInput>
            )}

            <Divider style={{ marginBottom: 30 }}>Conteúdo do Produto</Divider>

            <BoxInput>
              <label htmlFor="description">Descrição</label>
              <TextEditorBox>
                <ReactQuill
                  theme="snow"
                  value={editorTextValue}
                  onChange={setEditorTextValue}
                />
              </TextEditorBox>
            </BoxInput>

            <BoxInput>
              <label htmlFor="product_overview">Resumo do produto</label>
              <Field name="product_overview">
                {({ field }) => (
                  <div>
                    <textarea {...field} value={field.value || ''} />
                  </div>
                )}
              </Field>
            </BoxInput>

            <BoxInput>
              <label htmlFor="link_video">Link do Vídeo</label>
              <Field
                id="link_video"
                type="text"
                name="link_video"
                placeholder="Link do Vídeo"
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="card_background">Background do card</label>
              <Field
                id="card_background"
                type="text"
                name="card_background"
                placeholder="#021542"
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="card_tag_top">Card Tag superior</label>
              <Field
                id="card_tag_top"
                type="text"
                name="card_tag_top"
                placeholder="Nome da tag"
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="card_tag_bottom">Card Tag inferior</label>
              <Field
                id="card_tag_bottom"
                type="text"
                name="card_tag_bottom"
                placeholder="Nome da tag"
              />
            </BoxInput>

            {isProductTypeDefault && (
              <>
                <Divider style={{ marginBottom: 40, marginTop: 40 }}>
                  Dados para correção
                </Divider>

                <ProductQuestions
                  handleOnDragEnd={handleOnDragEnd}
                  openModalToUpdateProductQuestion={
                    openModalToUpdateProductQuestion
                  }
                  excluseProductQuestion={excluseProductQuestion}
                  productQuestions={productQuestions}
                  setProductQuestions={setProductQuestions}
                  productId={product_id}
                  getProduct={getProduct}
                />
              </>
            )}

            <ButtonContainer>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                loading={buttonLoading}
              >
                Atualizar produto
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </BoxForm>
  );

  const resourceModelsComponent = (
    <BoxForm>
      <UploadButton htmlFor="avatar">
        <input
          style={{ display: 'none' }}
          id="avatar"
          type="file"
          onChange={uploadResourceModelImage}
        />
        <div>
          {uploadLoading && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
          {!uploadLoading && (
            <>
              <MdUploadFile size={20} />
              <span>Upload</span>
            </>
          )}
        </div>
      </UploadButton>

      <ResourceList>
        {resourceModels.map((imageItem) => (
          <li>
            <a
              className="image"
              href={imageItem.image_url}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imageItem.image_url} alt="" />
            </a>
            <a
              className="link"
              href={imageItem.image_url}
              target="_blank"
              rel="noreferrer"
            >
              {imageItem.image}
            </a>
            <Button
              type="text"
              onClick={() => deleteProductResourceModels(imageItem.id)}
            >
              <MdDeleteOutline size={25} />
            </Button>
          </li>
        ))}
      </ResourceList>

      <ButtonContainer onClick={fakeSave}>
        <Button type="primary">Salvar</Button>
      </ButtonContainer>
    </BoxForm>
  );

  const productImagesComponent = (
    <BoxForm>
      <ProductImages>
        <div>
          <h2>Thumbnail</h2>
          <div>
            {initialValues.thumbnail_url && (
              <Image
                width={200}
                src={initialValues.thumbnail_url}
                style={{ marginTop: 30, marginBottom: 50 }}
              />
            )}

            <UploadButton htmlFor="thumbnail" style={{ marginLeft: 20 }}>
              <input
                style={{ display: 'none' }}
                id="thumbnail"
                type="file"
                onChange={(event) => uploadProductImages(event, 'thumbnail')}
              />
              <div>
                {uploadLoading && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                )}
                {!uploadLoading && (
                  <>
                    <MdUploadFile size={20} />
                    <span>Upload</span>
                  </>
                )}
              </div>
            </UploadButton>

            <Tooltip title="Remover Thumbnail">
              <Popconfirm
                title="Remover Thumbnail"
                description="Tem certeza que deseja remover a Thumbnail desse produto ?"
                onConfirm={() => removeProductImage('thumbnail')}
                okText="Confirmar"
                cancelText="Não"
                loading={buttonLoading}
              >
                <Button
                  icon={<MdDeleteOutline size={20} color="#fff" />}
                  style={{ background: '#ff4d4f', marginLeft: 15 }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>

        <div>
          <h2>Selo</h2>
          <div>
            {initialValues.stamp_url && (
              <Image
                width={200}
                src={initialValues.stamp_url}
                style={{ marginTop: 30, marginBottom: 50 }}
              />
            )}

            <UploadButton htmlFor="stamp" style={{ marginLeft: 20 }}>
              <input
                style={{ display: 'none' }}
                id="stamp"
                type="file"
                onChange={(event) => uploadProductImages(event, 'stamp')}
              />
              <div>
                {uploadLoading && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                )}
                {!uploadLoading && (
                  <>
                    <MdUploadFile size={20} />
                    <span>Upload</span>
                  </>
                )}
              </div>
            </UploadButton>

            <Tooltip title="Remover Selo">
              <Popconfirm
                title="Remover Selo"
                description="Tem certeza que deseja remover o Selo desse produto ?"
                onConfirm={() => removeProductImage('stamp')}
                okText="Confirmar"
                cancelText="Não"
                loading={buttonLoading}
              >
                <Button
                  icon={<MdDeleteOutline size={20} color="#fff" />}
                  style={{ background: '#ff4d4f', marginLeft: 15 }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>

        <div>
          <h2>Imagem descritiva do produto</h2>
          <div>
            {initialValues.imagem_about_product_url && (
              <Image
                width={200}
                src={initialValues.imagem_about_product_url}
                style={{ marginTop: 30, marginBottom: 50 }}
              />
            )}

            <UploadButton
              style={{ marginLeft: 20 }}
              onClick={() => setIsModalAddBannerOpen(true)}
            >
              <div>
                <MdUploadFile size={20} />
                <span>Upload</span>
              </div>
            </UploadButton>

            <Tooltip title="Remover Imagem descritiva">
              <Popconfirm
                title="Remover Imagem descritiva"
                description="Tem certeza que deseja remover Imagem descritiva desse produto ?"
                onConfirm={() => removeProductImage('imagem_about_product')}
                okText="Confirmar"
                cancelText="Não"
                loading={buttonLoading}
              >
                <Button
                  icon={<MdDeleteOutline size={20} color="#fff" />}
                  style={{ background: '#ff4d4f', marginLeft: 15 }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      </ProductImages>

      <ButtonContainer onClick={fakeSave}>
        <Button type="primary">Salvar</Button>
      </ButtonContainer>
    </BoxForm>
  );

  const productSummaryComponent = (
    <BoxForm>
      <Button type="primary" onClick={() => setIsModalAddSummary(true)}>
        Adicionar Resumo
      </Button>

      <ResourceList>
        {productSummary.map((imageItem) => (
          <li key={imageItem.id}>
            <a
              className="image"
              href={imageItem.icon_url}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imageItem.icon_url} alt="" />
            </a>
            <strong style={{ marginLeft: 15 }}>{imageItem.title}</strong>
            <Button
              type="text"
              onClick={() => deleteProductSummary(imageItem.id)}
            >
              <MdDeleteOutline size={25} />
            </Button>
          </li>
        ))}
      </ResourceList>

      <ButtonContainer onClick={fakeSave}>
        <Button type="primary">Salvar</Button>
      </ButtonContainer>
    </BoxForm>
  );

  const tabsContent = [
    {
      label: 'Dados gerais',
      key: 1,
      children: updateProductComponent,
    },
    {
      label: 'Imagens',
      key: 2,
      children: productImagesComponent,
      disabled: !isProductTypeDefault,
    },
    {
      label: 'Modelos de recursos',
      key: 3,
      children: resourceModelsComponent,
      disabled: !isProductTypeDefault,
    },
    {
      label: 'Icones',
      key: 4,
      children: productSummaryComponent,
      disabled: !isProductTypeDefault,
    },
    {
      label: 'Despachante virtual',
      key: 5,
      children: <DispatcherVirtual productId={product_id} />,
      disabled: !isProductTypeDefault,
    },
  ];

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/produtos">
          <NavLink to="/produtos">Produtos</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Atualização de produto" />

      {!loading && (
        <Tabs centered items={tabsContent.map((content) => content)} />
      )}

      {loading && <Loading />}

      {/* Modal Adiciona Resumo do produto */}
      <Modal
        title="Adicionar resumo"
        open={isModalAddSummary}
        footer={null}
        onCancel={() => setIsModalAddSummary(false)}
        width={700}
      >
        <BoxInput>
          <LabelButton style={{ cursor: 'pointer', display: 'flex' }}>
            <UploadOutlined />
            Upload icone
            <input
              style={{ display: 'none' }}
              id="questionName"
              type="file"
              name="questionName"
              placeholder="Título da questão"
              onChange={uploadProductSummary}
            />
          </LabelButton>

          <IconImage>
            <img src={summaryIconPreview} alt="" />
          </IconImage>
        </BoxInput>

        <BoxInput>
          <label htmlFor="summaryTitle">Título</label>
          <input
            id="summaryTitle"
            type="text"
            name="questionLimiteCaracteries"
            placeholder="Título do resumo"
            value={summaryTitle}
            onChange={(event) => setSummaryTitle(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="summaryDescription">Descrição</label>
          <input
            id="summaryDescription"
            type="text"
            name="summaryDescription"
            placeholder="Descrição"
            value={summaryDescription}
            onChange={(event) => setSummaryDescription(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button type="primary" onClick={createProductSummary}>
            Adicionar resumo
          </Button>
        </div>
      </Modal>

      {/* Modal Add Product Questions */}
      <Modal
        title="Cadastrar uma questão"
        open={isModalAddQuestionsOpen}
        footer={null}
        onCancel={closeModalAddProductQuestion}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="questionName">Título</label>
          <input
            id="questionName"
            type="text"
            name="questionName"
            placeholder="Título da questão"
            value={questionName}
            onChange={(event) => setQuestionName(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionLimiteCaracteries">
            Limite de caracteries
          </label>
          <input
            id="questionLimiteCaracteries"
            type="number"
            name="questionLimiteCaracteries"
            placeholder="Limite de caracteries da questão"
            value={questionLimiteCaracteries}
            onChange={(event) =>
              setQuestionLimiteCaracteries(event.target.value)
            }
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionScore">Nota máxima</label>
          <input
            id="questionScore"
            type="text"
            name="questionScore"
            placeholder="Nota máxima da questão"
            value={questionScore}
            onChange={(event) => setQuestionScore(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            onClick={() => productQuestionActions({ action: 'add' })}
          >
            Adicionar questão
          </Button>
        </div>
      </Modal>

      {/* Modal Update Product Questions */}
      <Modal
        title="Atualizar uma questão"
        open={isModalUpdateQuestionsOpen}
        footer={null}
        onCancel={closeModalAddProductQuestion}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="questionName">Título</label>
          <input
            id="questionName"
            type="text"
            name="questionName"
            placeholder="Título da questão"
            value={questionName}
            onChange={(event) => setQuestionName(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionLimiteCaracteries">
            Limite de caracteries
          </label>
          <input
            id="questionLimiteCaracteries"
            type="number"
            name="questionLimiteCaracteries"
            placeholder="Limite de caracteries da questão"
            value={questionLimiteCaracteries}
            onChange={(event) =>
              setQuestionLimiteCaracteries(event.target.value)
            }
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="questionScore">Nota máxima</label>
          <input
            id="questionScore"
            type="text"
            name="questionScore"
            placeholder="Nota máxima da questão"
            value={questionScore}
            onChange={(event) => setQuestionScore(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            onClick={() => productQuestionActions({ action: 'update' })}
          >
            Atualizar questão
          </Button>
        </div>
      </Modal>

      {/* Modal envio de e-mail para pre venda */}
      <ModalSendEmailToPresale
        isModalSendEmailPreSale={isModalSendEmailPreSale}
        setIsModalSendEmailPreSale={setIsModalSendEmailPreSale}
        productId={product_id}
      />

      {contextHolder}

      <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{ right: 24 }}
        icon={<AppstoreOutlined />}
      >
        <Tooltip title="Envio de email" placement="leftTop">
          <FloatButton
            icon={<SendOutlined />}
            onClick={() => setIsModalSendEmailPreSale(true)}
          />
        </Tooltip>
      </FloatButton.Group>

      {/* Modal Add Banner */}
      <Modal
        title="Cadastrar Imagem descritiva"
        open={isModalAddBannerOpen}
        footer={null}
        onCancel={() => setIsModalAddBannerOpen(false)}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="imageDesktop">Imagem Desktop</label>
          <input
            id="imagem_about_product"
            type="file"
            onChange={(event) =>
              uploadProductImages(event, 'imagem_about_product')
            }
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="imageMobile">Imagem Mobile</label>
          <input
            id="imagem_about_product_mobile"
            type="file"
            onChange={(event) =>
              uploadProductImages(event, 'imagem_about_product_mobile')
            }
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            onClick={() => setIsModalAddBannerOpen(false)}
            loading={uploadLoading}
          >
            Fazer upload
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
