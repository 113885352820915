/* eslint-disable react/no-unstable-nested-components */
import { Badge, Button, Card, Modal, Result, Table, Tag, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { format as formatPhone } from 'telefone';
import formatarValores from 'formatar-valores';
import {
  MdCheckCircleOutline,
  MdOutlineCancel,
  MdVisibility,
} from 'react-icons/md';
import currencyFormatter from 'currency-formatter';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getOrderCorrectionStatusColor } from '../../../../utils/getOrderCorrectionStatusColor';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';
import { getOrderPaymentStatusColor } from '../../../../utils/getOrderPaymentStatusColor';
import { getOrderPaymentStatus } from '../../../../utils/getOrderPaymentStatus';
import { api } from '../../../../services/api';
import { Pagination, TableActions } from './styles';

export function AdminOrdersTable({
  orders,
  loading,
  setShowModalApprovePayment,
  showModalApprovePayment,
  paginationPage,
  setPaginationPage,
  ordersTotal,
  isSearching,
  ordersTotalValue,
  setIsOpenModalOrderCancel,
  setOrderToCancel,
  allOrdersTotalValue,
  searchPaginationPage,
  setSearchPaginationPage,
}) {
  const [orderToApproved, setOrderToAppoved] = useState('');

  const columns = [
    {
      title: 'Id',
      dataIndex: 'product_id',
      key: '1',
      sorter: (a, b) => a.product_id - b.product_id,
    },
    {
      title: 'Produto',
      dataIndex: 'product_name_formated',
      key: '2',
      responsive: ['xs', 'xl', 'xxl'],
      render: (_, { product_name, product_name_formated }) => (
        <Tooltip title={product_name}>{product_name_formated}</Tooltip>
      ),
    },
    {
      title: 'Pedido',
      dataIndex: 'order_id',
      key: '3',
      sorter: (a, b) => a.order_number - b.order_number,
      render: (_, { order_id, have_virtual_dispatcher }) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span>{order_id}</span>
          {!!have_virtual_dispatcher && (
            <Tag style={{ marginTop: 10 }} color="purple" key="nice">
              Despachante
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Data da Compra',
      dataIndex: 'createdFormatted',
      key: '4',
    },
    {
      title: 'Candidato',
      dataIndex: 'client',
      key: '5',
      render: (client) => (
        <div>
          <p>{`Nome: ${client.name}`}</p>
          {client.cpf && <p>{`CPF: ${formatarValores.cpfCnpj(client.cpf)}`}</p>}
          <p>{`E-mail: ${client.email}`}</p>
          {client.phone_number && (
            <p>{`Telefone:  ${formatPhone(client.phone_number)}`}</p>
          )}
        </div>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'total_value_formatted',
      key: '6',
      sorter: (a, b) => a.total_value - b.total_value,
    },
    {
      title: 'Pagamento',
      key: '7',
      dataIndex: 'payment_status',
      render: (
        _,
        { payment_status, is_paid_with_two_cards, payment_status_two },
      ) => (
        <>
          {console.log('payment_status', payment_status)}
          {payment_status.map((tag) => (
            <Tag color={getOrderPaymentStatusColor(tag)} key="nice">
              {getOrderPaymentStatus(tag)}
            </Tag>
          ))}

          {is_paid_with_two_cards &&
            payment_status_two.map((tag) => (
              <Tag color={getOrderPaymentStatusColor(tag)} key="nice">
                {getOrderPaymentStatus(tag)}
              </Tag>
            ))}
        </>
      ),
    },
    {
      title: 'Correção',
      key: 'correction_status',
      dataIndex: 'correction_status',
      render: (_, { correction_status }) => (
        <>
          {correction_status.map((tag) => (
            <Tag color={getOrderCorrectionStatusColor(tag)} key="nice">
              {getOrderCorrectionStatus(tag)}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (order) => (
        <TableActions>
          <Tooltip title="Visualizar dados do pedido">
            <NavLink to={`/pedidos/visualizar/${order.id}`}>
              <Button
                icon={<MdVisibility size={20} color="#fff" />}
                href="https://www.google.com"
                style={{ background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Tooltip title="Aprovar pedido">
            <Button
              onClick={() => {
                setOrderToAppoved(order.id);
                setShowModalApprovePayment(true);
              }}
              style={{ margin: '0 10px', background: '#25D366' }}
              icon={<MdCheckCircleOutline size={20} color="#fff" />}
            />
          </Tooltip>

          <Tooltip title="Cancelar pedido">
            <Button
              onClick={() => {
                setIsOpenModalOrderCancel(true);
                setOrderToCancel(order);
              }}
              icon={<MdOutlineCancel size={20} color="#fff" />}
              style={{ background: '#ff4d4f' }}
            />
          </Tooltip>
        </TableActions>
      ),
    },
  ];

  const approveOrderPayment = useCallback(async () => {
    try {
      await api.get(`/orders/pay/${orderToApproved}`);
      setShowModalApprovePayment(false);

      toast.success('Pagamento aprovado com sucesso');
    } catch (error) {
      toast.error('Erro ao aprovar pagamento');
    }
  }, [orderToApproved]);
  return (
    <>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        footer={() => (
          <div>
            {!isSearching && (
              <p>{`Total de pedidos da página: ${orders.length}`}</p>
            )}

            {!isSearching && (
              <p>
                {`Valor total da página: ${currencyFormatter.format(
                  ordersTotalValue,
                  {
                    code: 'BRL',
                  },
                )}`}
              </p>
            )}
            <p>{`Total de pedidos: ${ordersTotal}`}</p>
            <p>
              {`Valor total: ${currencyFormatter.format(allOrdersTotalValue, {
                code: 'BRL',
              })}`}
            </p>
          </div>
        )}
        pagination={false}
        style={{ overflow: 'scroll' }}
      />

      {!isSearching && (
        <Pagination
          onChange={setPaginationPage}
          current={paginationPage}
          total={ordersTotal}
          showSizeChanger={false}
          defaultPageSize={20}
        />
      )}

      {isSearching && (
        <Pagination
          onChange={setSearchPaginationPage}
          current={searchPaginationPage}
          total={ordersTotal}
          showSizeChanger={false}
          defaultPageSize={20}
        />
      )}

      <Modal
        title="Aprovação de pagamento"
        open={showModalApprovePayment}
        footer={null}
        onCancel={() => setShowModalApprovePayment(false)}
        width={700}
        style={{ textAlign: 'center' }}
      >
        <Result
          status="warning"
          title="Tem certeza que deseja aprovar o pagamento desse pedido ?"
        />

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            style={{ background: '#25d366' }}
            color="green"
            type="primary"
            onClick={approveOrderPayment}
          >
            Aprovar pagamento
          </Button>
        </div>
      </Modal>
    </>
  );
}
