/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Categories } from '../pages/Categories';
import { CategoriesCreate } from '../pages/CategoriesCreate';
import { CategoriesUpdate } from '../pages/CategoriesUpdate';

import Login from '../pages/Login';
import { ProductCreate } from '../pages/ProductCreate';
import { Products } from '../pages/Products';
import { Users } from '../pages/Users';
import { UsersCreate } from '../pages/UsersCreate';
import { PrivateRoute } from './PrivateRoute';
import { Logout } from '../pages/Logout';
import { UsersUpdate } from '../pages/UsersUpdate';
import { ProductUpdate } from '../pages/ProductUpdate';
import { Orders } from '../pages/Orders';
import { OrderShow } from '../pages/OrderShow';
import { Corrections } from '../pages/Corrections';
import { Correction } from '../pages/Correction';
import { CorrectionToPrint } from '../pages/CorrectionToPrint';
import { MessageCenter } from '../pages/MessageCenter';
import { MessageCenterCreate } from '../pages/MessageCenterCreate';
import { MessageCenterUpdate } from '../pages/MessageCenterUpdate';
import { Reports } from '../pages/Reports';
import { Banners } from '../pages/Banners';
import { HomeProducts } from '../pages/HomeProducts';
import PasswordForgot from '../pages/PasswordForgot';
import { StoreConfings } from '../pages/StoreConfings';

function RoutesWraper() {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />

      <Route exact path="/recuperacao-senha" element={<PasswordForgot />} />

      <Route
        exact
        path="/correction-to-print/:order_id"
        element={<CorrectionToPrint />}
      />

      {/* Pedidos */}
      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/pedidos/visualizar/:order_id"
        element={
          <PrivateRoute>
            <OrderShow />
          </PrivateRoute>
        }
      />

      {/* User Correções */}
      <Route
        exact
        path="/meus-recursos"
        element={
          <PrivateRoute>
            <Corrections />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/correcao/:order_id"
        element={
          <PrivateRoute>
            <Correction />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/correcao/coordenador/:order_id"
        element={
          <PrivateRoute>
            <Correction />
          </PrivateRoute>
        }
      />

      {/* Users */}
      <Route
        exact
        path="/usuarios"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/usuarios/criar"
        element={
          <PrivateRoute>
            <UsersCreate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/usuarios/editar/:user_id"
        element={
          <PrivateRoute>
            <UsersUpdate />
          </PrivateRoute>
        }
      />

      {/* Produtos */}
      <Route
        exact
        path="/produtos"
        element={
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/produtos/criar"
        element={
          <PrivateRoute>
            <ProductCreate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/produtos/editar/:product_id"
        element={
          <PrivateRoute>
            <ProductUpdate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/categorias"
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/categorias/criar"
        element={
          <PrivateRoute>
            <CategoriesCreate />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/categorias/editar/:product_categorie_id"
        element={
          <PrivateRoute>
            <CategoriesUpdate />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/logout"
        element={
          <PrivateRoute>
            <Logout />
          </PrivateRoute>
        }
      />

      {/* Central de mensagens */}
      <Route
        exact
        path="/central-mensagens"
        element={
          <PrivateRoute>
            <MessageCenter />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/central-mensagens/criar"
        element={
          <PrivateRoute>
            <MessageCenterCreate />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/central-mensagens/editar/:central_message_id"
        element={
          <PrivateRoute>
            <MessageCenterUpdate />
          </PrivateRoute>
        }
      />

      {/* Relatórios */}
      <Route
        exact
        path="/relatorios"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />

      {/* Banners */}
      <Route
        exact
        path="/banners"
        element={
          <PrivateRoute>
            <Banners />
          </PrivateRoute>
        }
      />

      {/* Produtos Destaque */}
      <Route
        exact
        path="/produtos-destaque"
        element={
          <PrivateRoute>
            <HomeProducts />
          </PrivateRoute>
        }
      />

      {/* Configurações */}
      <Route
        exact
        path="/configuracoes"
        element={
          <PrivateRoute>
            <StoreConfings />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default RoutesWraper;
